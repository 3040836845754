/**
 * plugins/webfontloader.ts
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts () {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: ["Montserrat","Staatliches"],
    },
    custom: {
      families: ['rpt Bold','GE Dinar One Medium','GE Dinar One Light']
    }
  })
}

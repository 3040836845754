export const messages = {
  en: {
    lang: "العربية",
    sikka: "SIKKA",
    tagline: "Archiving and documenting the thought,<b> arts</b> and <b>literature of </b> the Sudanese revolution</b>",
    heroText1: "Sikka is a platform that seeks to be a transparent horizon to follow the path of the Sudanese and their creativity to derive new patterns of freedom",
    heroText2: "With Sikka, we are introduced to the narratives that formed the uniqueness and creativity of the Sudanese revolution.",
    homeGallery1: `Sikkas Gallery`,
    homeGalleryButton: `explore`,
    homeGallery2: `presents visual history of the Sudanese revolution`,
    articlesSubheading: "Tales written during the revolution",
    readMore: 'read more',
    podcastsSubheading: "Multi-narratives of a path perpetuated by its prospectors.",
    sikkaGallery: "SIKKA GALLERY",
    sikkaVideos: "SIKKA Videos",
    sikkaPosters: "Sikka Posters",
    sikkaPostersSubtitle: "sikka posters subtitle",
    ST: "Stay Tuned",
    menu: {
      home: 'HOME',
      about: "ABOUT SIKKA",
      gallery: "GALLERY",
      videos: "VIDEOS",
      articles: "ARTICLES",
      podcasts: "PODCASTS",
    },
    aboutPage: {
      subheading: "At Sikka, we aspire to portray the beauty within the chaos",
      heroText: "Sikka is a platform whose entire purpose is to document with an essence of archiving memorable, iconic moments and events that have truly shaped the feel of the revolution",
      wwd: "We document, with an archive feel and go in-depth in every aspect, whether its artistic, cultural, economic, and more analyzing and illustrating the soul of the revolution through a website that takes the user through a journey of exploring different time stations",
      teamTitle: "SIKKAS TEAM",
      teamSubheading: "Meet the Sikka team",
      smTitle: "SOCIAL MEDIA",
      smSubheading: "Get our latest updates",
      sikkaStory: "Sikka Story"
    },
    articlesPage: {
      subheading: "Archiving and documenting the thought, arts and literature of the Sudanese revolution",
      heroText2: " The Sudanese circulated multiple narratives to describe the same path that brought them to a life that seemed to be the one the Sudanese wanted.",
    },
    podcasts: {
      categories: "Listen to a collection of sikka’s podasts"
    },
    team: {
      munaf: {
        name: "Muhammad Manaf",
        desc: "Is the  Media Labs Project Manager (Sikka). He's a lover of programming, graphic design, and tennis."
      },
      alsadig: {
        name: "Alsadig Yaseen",
        desc: "A writer and researcher in cultural production, he employs his efforts in writing articles and texts, and editing writings for audio and visual materials."
      },
      bassel: {
        name: "Basel Hassan",
        desc: "Scene creator, the manufacturer of audiovisual content, is working to include his experience within SIKKA's work system."
      },
      fatma: {
        name: "Fatma Altigany",
        desc: "Journalist and communication officer and coordination of efforts between the SIKKA platform team and cultural actors and creators."
      },
      hassan: {
        name: "Hassan Adil",
        desc: "Digital marketing & E commerce specialist and I am team moderator of sikka’s team ."
      },
      ismeal: {
        name: "Mohamed Ismail mohamed (teyor)",
        desc: "Filmmaker, wildlife photographer, and camping lover."
      },
      mamoun: {
        name: "Mamoun Eltlib",
        desc: "A poet, writer and a cultural editor in many newspapers and magazines."
      },
      mujahid: {
        name: "Mujahid Eldouma",
        desc: "Writer, journalist, and translator, interested in Arts and social sciences."
      },
      nashwa: {
        name: "Nashwa Serageldin Bardab",
        desc: "Graphic Designer and Motion Graphic is responsible for the production of multimedia design within the Sikka team."
      },
      sameh: {
        name: "Samah Hassan Magbool Mohammed",
        desc: "Supervising physiotherapist and assistant of the Sikka project interested in art, music and culture."
      }
    }
  },
  ar: {
    lang: "English",
    sikka: "سِكَّة",
    tagline: "أَرشَفَة وتوثيق <b>فِكر، فُنُون وآداب الثورة السودانيّة</b>",
    heroText1: "'سِكَّة' منصَّة تلتمس أن تكون أُفقاً شفَّافاً لتتُبع مسير السودانيين وإبداعهم لاستخلاص أنماطٍ جديدة للحرية",
    heroText2: "برفقة ‘سكة’ نتعرف على سرديات تكوين الثورة السودانية بتنوّعها وإبداعها",
    homeGallery1: `مشاهد السكة`,
    homeGalleryButton: `اكتشف`,
    homeGallery2: `مزارٌ بصريّ لملاحم صمود الثورة السودانية`,
    articlesSubheading: "أُقصوصات ونصوص أدبيَّة كُتِبَت في أزمنة الثورة",
    readMore: 'اقرأ المزيد',
    podcastsSubheading: "سرديات متعدّدة لسكة خلدها مُرتاديها",
    sikkaGallery: "مشاهد سكه",
    sikkaVideos: "فيديوهات سكه",
    sikkaPosters: "بوسترات سكة",
    sikkaPostersSubtitle: "عنوان بوسترات سكة",
    ST: "ترقبوا المزيد",
    menu: {
      home: "الرئيسية",
      about: "عن سكة ",
      gallery: "مشاهد السكة",
      videos: "فيديوهات",
      articles: "المقالات",
      podcasts: "بودكاست",
    },
    aboutPage: {
      subheading: "في سكة، نطمح إلى تصوير الجمال وسط الفوضى",
      heroText: "سكة.. ربما تسلّحنا باستواء مقصد السودانيين وحلمهم المتبوع بالعمل، لنُحِيلَ دروباً عديدة اجتازها السودانيين إلى سكّةٍ واحدة، ونتَجاسر بتتبع المعاني المرتبطة بالسكة لنجد رغبة مستمرة حثيثة لانتقال بين غايتين، وبذلك  تنتمي السكة إلى الفعل ذاته، لا معناه كسِكَّة لذَاتِهَا",
      wwd: "يسعى فريق 'سكة' لتعزيز حضور الثقافة والفنون وتداخلها في تشكيل معالم الانتقال والثورةالسودانية، وأن يُقدِّم موادّاً نصيةً وبصرية، تُحفِّز على الانشغال الدائم بتحقيق الإجابة على أسئلة الحريات والحقوق والديمقراطية وسيادة حكم القانون، من خلال توثيق مساحات تفاعل الفاعلين السياسيين والثقافيين والأدباء والفنانين والفاعلين المجتمعيين في دفاتر حضور سجلات الانتقال المدني الديمقراطي في السودان.",
      teamTitle: "أعضاء الفريق",
      teamSubheading: "تعرف على فريق سِكّة",
      smTitle: "وسائل التواصل الاجتماعي",
      smSubheading: "احصل على آخر التحديثات ",
      sikkaStory: "قصة سكة"
    },
    articlesPage: {
      subheading: "أَرشَفَة وتوثيق فِكر، فُنُون وآداب الثورة السودانيّة",
      heroText2: " تداول السودانيون سرديات متعدّدة لوصف ذات السكة التي اوصلتهم لحياة بَدَت وكأنها التي أرداها السودانيون",
    },
    podcasts: {
      categories: "إستمع الآن إلى مجموعة من بودكاستات سِكّة"
    },
    team: {
      munaf: {
        name: "محمد مناف",
        desc: "مدير مشروع ميديا لابز (سكه)، محب للبرمجة والتصميم الجرافيكي، عاشق للتنس"
      },
      alsadig: {
        name: "الصادق يس",
        desc: "كاتب، وباحث في الإنتاج الثقافي، يوظف جهده في كتابة  المقالات والنصوص، وتحرير الكتابات للمواد المسموعة والمرئية."
      },
      bassel: {
        name: "باسل حسن",
        desc: "مبتكر المشاهد و صناعة المحتوى المرئي المسموع ، يعكف على إدراج خبرته داخل سكة ."
      },
      fatma: {
        name: "فاطمة التجاني",
        desc: "صحفية ومسؤولة التواصل وتنسيق الجهود بين فريق منصة سكة والفاعلين الثقافيين والمبدعين."
      },
      hassan: {
        name: "حسن عادل",
        desc: "متخصص تسويق رقمي وتجارة إلكترونية ومسؤول من إدارة فريق عمل سكة"
      },
      ismeal: {
        name: "محمد اسماعيل محمد (طيور)",
        desc: "صانع أفلام، مصور حياة برية وهاوي للتخييم"
      },
      mamoun: {
        name: "مأمون التلب",
        desc: "شاعر وكاتب ومحرر ثقافي بعدة صحف ومجلات ورقية وإلكترونية."
      },
      mujahid: {
        name: "مجاهد الدومة",
        desc: "كاتب، صحفي ومترجم مهتم بالفنون والعلوم الاجتماعية."
      },
      nashwa: {
        name: "نشوى سراج الدين بارداب",
        desc: "مصممة جرافيك و موشن جرافيك مسؤولة عن إنتاج تصميم الوسائط المتعددة ضمن فريق منصة سكة."
      },
      sameh: {
        name: "سماح حسن مقبول محمد",
        desc: "معالجة طبيعية مشرفة ومساعدة مشروع سكة مهتمة بالفن والموسيقى والثقافة"
      }
    }
  }
}

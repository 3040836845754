<template>
  <Transition :duration="0.8">
    <v-alert v-if="show" style="position: fixed; left: 0; bottom: 0;z-index: 1000000;" color="#25769E" theme="dark"
      icon="mdi-cookie" prominent>
      <v-row>
        <v-col>
          We use essential cookies to make Sikka work. We’d like to use other cookies to improve your
          visit to analyze our website’s performance, but only
          if you accept. Learn more about your choices in our <router-link to="/privacy-policy">privacy
            policy</router-link> .
        </v-col>
      </v-row>
      <v-row class="d-flex">
        <v-col class="justify-space-around">
          <v-btn @click="acceptCookies" color="success">
            Accept
          </v-btn>
          <v-btn @click="ignoreCookies" color="white" variant="text">
            ignore
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </Transition>
</template>
<script setup lang='ts'>
import { ref } from 'vue';
import { VAlert } from 'vuetify/lib/components/index.mjs';
import { setOptions, bootstrap } from 'vue-gtag'
import moment from 'moment';
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies()
const gtagConstent = cookies.get('gtag_consent')
const show = ref(gtagConstent ? false : true)

const acceptCookies = async () => {
  cookies.set('gtag_consent', `${moment().format('DD/MM/YYYY@HH:MM:SS')}`)
  setOptions({
    config: { id: 'G-GWHJDXLFGW' }
  })
  await bootstrap().then((_gtag) => { })
  show.value = !show.value
}

const ignoreCookies = () => {
  show.value = !show.value
}
</script>

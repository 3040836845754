import App from './App.vue'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { messages } from './plugins/i18n'
import { AVPlugin } from 'vue-audio-visual'
import { VuePrlx } from 'vue3-prlx'
import { createHead } from '@unhead/vue'
import { registerPlugins } from '@/plugins'
import { InferSeoMetaPlugin } from '@unhead/addons'

export const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en', // set locale
  fallbackLocale: 'ar', // set fallback locale
  useScope: 'global',
  messages, // set locale messages
})
const head = createHead({ plugins: [InferSeoMetaPlugin()] })

const pinia = createPinia()

const app = createApp(App)

registerPlugins(app)
app.use(head)
app.use(i18n)
app.use(pinia)
app.use(VuePrlx)
app.use(AVPlugin)
app.mount('#app')




import { createRouter, createWebHistory } from 'vue-router'
import { ref } from 'vue'

//routes: home, about us, gallery, videos, articles
const Error = () => import('@/views/Error.vue')
const Home = () => import('@/views/Home.vue')
const About = () => import('@/views/About.vue')
const PrivacyPolicy = () => import('@/views/PrivacyPolicy.vue')
const Gallery = () => import('@/views/Gallery.vue')
const Videos = () => import('@/views/Videos.vue')
const Articles = () => import('@/views/Articles.vue')
const ArticlesSubPage = () => import('@/views/ArticlesSubpage.vue')
const VideosSubpage = () => import('@/views/VideosSubpage.vue')
const Posters = () => import('@/views/Posters.vue')
const GallerySubpage = () => import('@/views/GallerySubpage.vue')
const Podcast = () => import('@/views/PodcastSubpage.vue')
const Podcasts = () => import('@/views/Podcasts.vue')

export const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/error',
    component: Error,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/gallery',
    component: Gallery,
  },
  {
    path: '/gallery/:id',
    component: GallerySubpage,
  },
  {
    path: '/gallery/posters',
    component: Posters,
  },
  {
    path: '/videos',
    component: Videos,
  },
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/podcasts',
    component: Podcasts,
  },
  {
    path: '/articles/:id',
    component: ArticlesSubPage
  }
  ,
  {
    path: '/videos/:id',
    component: VideosSubpage
  }
  ,
  {
    path: '/podcasts/:id',
    component: Podcast
  }
]

export const router = createRouter({

  history: createWebHistory(),
  routes,
  scrollBehavior(to, _from, _savedPosition) {
    // always scroll to top
    if (to.hash) {


      return new Promise((resolve, __) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: -1000,
            behavior: 'smooth',
          })
        }, 500)
      })
    } else return { top: 0 }

  },

})
export const loading = ref(false)
router.beforeEach((to, from, next) => {
  loading.value = true
  next()
})
router.afterEach(() => {
  setTimeout(() => loading.value = false, 1000)
})

export default router

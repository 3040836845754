<template>
  <v-app class="app">
    <Header />
    <Loader v-if="loading"/>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense :timeout="0">
          <component :is="Component"></component>
          <template #fallback>
            <Loader />
          </template>
        </Suspense>
      </template>
    </RouterView>
    <Footer />
    <CookieBanner/>
  </v-app>
</template>

<script lang="ts" setup>
import Loader from './components/Global/Loader.vue';
import CookieBanner from '@/components/Global/CookieBanner.vue'
import { defineAsyncComponent, provide, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { voidFunction, order as TypeOrder } from './typesAndData/types';
import { loading } from "@/router/index"
import { useHead } from '@unhead/vue';
const Header = defineAsyncComponent(() => import('./components/Global/Header.vue'))
const Footer = defineAsyncComponent(() => import('./components/Global/Footer.vue'))
// const CookieBanner = defineAsyncComponent(() => import('./components/Global/CookieBanner.vue'))

const order: Ref<TypeOrder> = ref({
  first: 1,
  second: 2
})
const { locale, t } = useI18n()
const updateOrder: voidFunction = () => {
  order.value.first = locale.value == "en" ? 1 : 2
  order.value.second = locale.value == "en" ? 2 : 1
}


provide("order", {
  order,
  updateOrder
})

useHead({
  titleTemplate: (title?: string) => `${t('sikka')} - ${title}`,
})
</script>
<style lang="scss">
@import '@/scss/app';

html,
body,
.app {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: none;
}

* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}
</style>

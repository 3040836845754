/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
// import pinia from '../store'
import router from '../router/index'
import VueGtag from "vue-gtag";
import VueCookies from 'vue3-cookies'
// Types
import type { App } from 'vue'
// export const enableGtag
export function registerPlugins(app: App) {

  loadFonts()
  app
    .use(vuetify)
    .use(router)
    .use(VueCookies, {
      expireTimes: "6m",
      domain: "sikka.sd",
      secure: true,
    })
    .use(VueGtag, {
      bootstrap: false,
      // // enabled:false,
      // config: {
      //   id: "G-GWHJDXLFGW",
      // },
    }, router)
  // .use(pinia)
}
